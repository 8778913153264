
html, body {
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

body {
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Libre Franklin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg:hover .st0, svg:hover .apple-bg {
  fill:#5f63e1;
}

svg .apple-bg {
  fill:black;
}

svg .ios {
  /*fill:#5f63e1;*/
  fill:white;
}

svg:hover .ios {
  fill:#f2f2f7;
}

svg#ios:hover {
  fill:#5f63e1;
}

.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

/*.card {
  flex: 1;
}

.front, .back {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  backface-visibility: hidden;
  transition: transform .6s linear;
}
.front {
  overflow: scroll;
  transform: perspective(600px) rotateY(0deg);
}
.back {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transform: perspective(600px) rotateY(-180deg);
}

.card.rotated > .front {
  transform: perspective(600px) rotateY(180deg);
}
.card.rotated > .back {
  background-color: white;
  transform: perspective(600px) rotateY(0deg);
}


.html-doctype {
  color: rgb(192, 192, 192);
}

.html-tag {
  color: rgb(136, 18, 128);
}

.html-attribute-name {
  color: rgb(153, 69, 0);
}

.html-attribute-value {
  color: rgb(26, 26, 166);
}

.html-resource-link {
  color: rgb(0, 0, 238);
}

.html-comment {
  color: rgb(35, 110, 37);
}

.line-number {
  box-sizing: border-box;
  width: 31px;
  background-color: -internal-light-dark(rgb(240, 240, 240), rgb(60, 60, 60));
  user-select: none;
  text-align: right;
  color: -internal-light-dark(rgb(148, 132, 132), rgb(128, 128, 128));
  word-break: normal;
  white-space: nowrap;
  font-size: 9px;
  font-family: Helvetica;
  padding: 0px 4px !important;
  border-right: 1px solid rgb(187, 187, 187) !important;
}

.line-number::before {
  content: attr(value);
}

table {
  border-collapse: separate;
  text-indent: initial;
  white-space: pre;
  font-size: initial;
  font-family: monospace;
  tab-size: 4;
  border-spacing: 0px;
}*/